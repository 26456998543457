














import Vue from 'vue';
import { mapGetters } from 'vuex';
import NotificationSettingsCard from '@/components/Settings/NotificationSettings.vue';

export default Vue.extend({
  name: 'NotificationSettings',
  components: {
    NotificationSettingsCard,
  },
  computed: {
    ...mapGetters('user', {
      settings: 'notificationSettings',
    }),
  },
});
